.containerBackground {
    height: 100vh;
    width: 100vw;
    background-image: url(http://circular.rxy.com.br/api/public/modelo01.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 25%;
}

#footer {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100px;
    background-color: #fff;
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#containerText {
    padding-top: 20%;
    padding-left: 10%;
}

#containerText h1 {
    color: #fff;
    font-size: 84px !important;
}

#containerText p {
    color: #fff;
    font-size: 24px !important;
}

@media(min-width: 640px) {
    .containerBackground {
        height: 100vh;
        width: 100vw;
        background-image: url(http://circular.rxy.com.br/api/public/modeo1a.jpg);
        background-position: right 25%;
    }

    #containerText {
        padding-top: 10%;
        padding-left: 10%;
    }
    
    #footer {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 150px;
        background-color: #fff;
        width: 80vw;
    }

    #findSeller {
        width: 30%;
        height: 30%;
    }
}