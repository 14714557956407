body {
    /* background-image: url(http://circular.rxy.com.br/api/public/modeo1a.jpg?a=1);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: 50% 25%; */
    background-color: #015ca9 !important;
}

.gazin-logo {
    align-items: center!important;
    justify-content: center!important;
    display: flex;
    margin: 30px 0px;
}

.welcome-tabloid {
    background-color: transparent !important;
    margin-bottom: 30px;
}

.welcome-tabloid p, .welcome-tabloid h1 {
    color: #fff !important
}

.containerBackground2 h1, h2, h3, h4, p {
    color: #fff;
    /* font-size: 64px !important; */
}

.containerBackground2 p {
    margin: 0 !important;

}

.sellerPhoto img {
    width: 100%;
}

.image-parent {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    align-items: center!important;
    display: flex;
}

.image-parent img {
    width: 100%;
    height: auto;
}

.seller-name {
    text-transform: capitalize;
}

.item-seller {
    align-items: center!important;
    justify-content: space-between!important;
}

.item-seller .buttons-contact {
    margin-top: 10px;
}

.item-seller .buttons-contact {
    width: 100%;
}

.item-seller .name-photo {
    align-items: center!important;
    display: flex!important;
    position: relative;
}

.item-seller .name-photo .seller-name {
    margin-left: 10%;
    font-size: 18px;
    width: 60%;
}

.seller-position {
    /* margin-top: 10px; */
}

.address-list-item {
    margin: 10px 0px;
    cursor: pointer;
}

@media(min-width: 640px) {
    .seller-name {
        text-transform: capitalize;
    }
    
    .item-seller {
        display: flex!important;
        align-items: center!important;
        justify-content: space-between!important;
    }

    .item-seller .buttons-contact {
        margin-top: 0px;
    }

    .item-seller .name-photo {
        align-items: center!important;
        display: flex!important;
        width: 40%;
    }

    .item-seller .buttons-contact {
        width: auto;
    }

    .card-title {
        min-height: 50px;
    }

    .card-seller {
        min-height: 430px;
    }
    
    .sellerPhoto {
        min-height: 200px;
        max-height: 200px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .sellerPhoto img {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}